<template>
    <v-card class="mb-2" elevation="0" outlined>
        <v-card-text class="py-0 my-0">
          <v-row align="center"> <!-- Ensures content is aligned vertically -->
            <v-col cols="auto"> <!-- Auto width for the avatar column -->
              <v-avatar
                :class="isDarkTheme ? 'slight-radius_dark' : 'slight-radius_light'"
                :color="userColor"
                class="pa-0 ma-0"
                :size="this.$route.params.tab === 'general' ? 36 : 40">
                <span class="white--text scale-text">{{ initials }}</span>
              </v-avatar>
            </v-col>
            <v-col v-if="this.$route.params.tab === 'general'" class="px-0 py-2 ma-0"> 
                <!-- Remaining space for the text -->
                <div class="d-flex justify-space-between align-left">
                    <!-- Left side elements -->
                    <div class="d-flex align-center ellipsis">
                        <span class="scale-text-general">{{ fullName }}</span>
                        <v-icon
                            :key="data.account_online"
                            size="6"
                            :color="data.account_online ? 'green' : 'red'"
                            class="icon_item ml-1">
                            {{ $vuetify.icons.values.circle }}
                        </v-icon>
                        <span class="ml-2 scale-text-general">{{ formattedDate }}</span>
                    </div>
                    <!-- Right side icon with dropdown menu -->
                    <v-menu v-if="this.userName === this.$props.data.account_display_name" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                class="ml-auto"
                                v-bind="attrs"
                                v-on="on">
                                {{ icons.mdiMenu }}
                            </v-icon>
                        </template>
                        <v-list width="150">
                            <!--<v-list-item class="py-0 my-0" disabled @click="editItem">
                                <v-icon depressed class="mr-2" size="20">{{ icons.mdiPencil }}</v-icon>
                                <v-list-item-subtitle>Edit</v-list-item-subtitle>
                            </v-list-item>-->
                            <v-list-item class="py-0 my-0" @click="deleteItem">
                                <v-icon class="mr-2" size="20">{{ icons.mdiTrashCan }}</v-icon>
                                <v-list-item-subtitle>Delete</v-list-item-subtitle>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
              <div>{{ data.value }}</div>
            </v-col>
            <v-col v-else class="px-0 py-0 ma-0"> 
                <div class="d-flex justify-space-between align-center">
                <!-- Left side elements -->
                <div class="d-flex flex-column align-start">
                    <div class="d-flex align-center">
                        <span class="scale-text-peripherals">{{ fullName }}</span>
                        <v-icon
                            :key="data.account_online"
                            size="6"
                            :color="data.account_online ? 'green' : 'red'"
                            class="icon_item ml-1">
                            {{ $vuetify.icons.values.circle }}
                        </v-icon>
                    </div>
                    <span class="scale-text-peripherals">{{ formattedDate }}</span>
                </div>

                <!-- Right side icon with dropdown menu -->
                <v-menu v-if="this.userName === this.$props.data.account_display_name" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            class="ml-auto"
                            v-bind="attrs"
                            v-on="on">
                            {{ icons.mdiMenu }}
                        </v-icon>
                    </template>
                    <v-list width="150">
                        <v-list-item class="py-0 my-0" @click="deleteItem">
                            <v-icon class="mr-2" size="20">{{ icons.mdiTrashCan }}</v-icon>
                            <v-list-item-subtitle>Delete</v-list-item-subtitle>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
              <div>{{ data.value }}</div>
            </v-col>
          </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import { mdiDotsVertical, mdiPencil, mdiTrashCan } from '@mdi/js';
import { mapGetters } from "vuex";

export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        isDarkTheme: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            icons: {
                mdiMenu: mdiDotsVertical,
                mdiPencil: mdiPencil,
                mdiTrashCan: mdiTrashCan
            }
        }

    },
    methods:{
        editItem() {
            this.$emit('edit-comment', this.data); 
        },
        deleteItem() {
            this.$emit('delete-comment', this.data.uuid); // Similar assumption as above
        }
    },
    computed: {
        ...mapGetters({ userName: "accounts/getAccountName" }),
        initials() {
            let matches = this.data.account_display_name.match(/\b(\w)/g);
            return matches.join('').toUpperCase();
        },
        userColor() {
            const colors = {
                true: "#2B85D8",  // Assuming 'true' means online
                false: "#7A7A7A"
            };
            return colors[this.data.account_online];
        },
        fullName() {
            return `${this.data.account_display_name}`;
        },
        formattedDate() {
            const date = new Date(this.data.created_at);
            const now = new Date();
            const differenceInSeconds = Math.floor((now - date) / 1000);
            const daysAgo = Math.floor(differenceInSeconds / (3600 * 24));

            const hours = date.getHours().toString().padStart(2, '0'); // Local hours
            const minutes = date.getMinutes().toString().padStart(2, '0'); // Local minutes
            const seconds = date.getSeconds().toString().padStart(2, '0'); // Local seconds

            if (daysAgo === 0) {
                return `Today, ${hours}:${minutes}:${seconds}`;
            } else if (daysAgo === 1) {
                return `Yesterday, ${hours}:${minutes}:${seconds}`;
            } else {
                return `${daysAgo} days ago, ${hours}:${minutes}:${seconds}`;
            }
        }


    }
}
</script>

<style>
.slight-radius_dark {
    border-radius: 8px;
}

.slight-radius_light {
    border-radius: 8px;
}

.green--text {
    color: #4CAF50; /* Material Design green */
}

.grey--text {
    color: #9E9E9E; /* Material Design grey */
}
.scale-text-general {
  font-size: calc(5px + 0.5vw); /* Adjusts the base size and scaling factor to fit your design */
}
.scale-text-peripherals {
  font-size: calc(3px + 0.5vw); /* Adjusts the base size and scaling factor to fit your design */
}


/* Additional styles can be added here if needed */
</style>
