<template>
  <div>
    <v-row>
      <v-col>
        <FlyoutMenuHead type="edit" :selectedItem="getSelectedGroup" route="groups"/>
      </v-col>
    </v-row>
    <v-divider/>
    <v-row class="mt-4">
      <v-col md="12" class="d-flex align-center justify-space-between pt-4">
        <span class="subtitle text-secondary">Locations</span>
      </v-col>
    </v-row>
    <v-form
      class="overflow-container overflow-y-auto scroll-right-md-y scroll-right-lg-y"
      v-model="isFormValid"
      @submit.prevent="submitForm"
    >
    <!--Always use submit pervent it pervents the page from reloading after submition-->
      <v-row>
        <v-col md="8" class="d-flex align-center justify-space-between pb-0">
          <v-text-field
            v-model="location.name"
            class="pt-0 mb-0"
            height="40"
            :maxlength="256"
            :counter="256"
            placeholder="Friendly name for your location group"
            :rules="[rules.required]"
            dense
            outlined
          >
            <template #label>
              <span class="pl-1 pr-1">Location Group</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="overflow-y-auto">
        <v-col md="8" class="d-flex align-center justify-space-between">
          <v-autocomplete
            v-model="location.locations"
            :items="locations"
            placeholder="Enter locations for your location group"
            dense
            outlined
            :append-icon="$vuetify.icons.values.chevronDown"
            item-text="name"
            item-value="uuid"
            chips
            persistent-hint
            deletable-chips
            multiple
          >
            <template #label>
              <span class="pl-1 pr-1">Location list</span>
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title class="d-flex align-start justify-space-between">
                  <span class="font-weight-regular">{{ data.item.name }}</span>
                  <span class="font-weight-light">{{ data.item['total_number'] || 0 }} devices and locations</span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:no-data>
              <v-list-item-content>
                <v-list-item-title class="d-flex align-start justify-space-between px-4">
                  <span class="font-weight-light caption">New</span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="8" class="d-flex align-center justify-space-between pb-0">
          <span class="ml-2 subtitle text-secondary">Active</span>
          <v-spacer></v-spacer>
          <v-switch v-model="location.active"></v-switch>
        </v-col>
      </v-row>
      <v-row class="text-right bottom-navigation-edit py-4 px-2"
        v-bind:style="[this.$vuetify.theme.dark ? {'background-color': '#1E1E1E'} : {'background-color':'#ffffff'}]"
      >
        <v-col>
          <v-btn
            width="120"
            class="mr-2 font-weight-regular letter-spacing-normal text-capitalize"
            depressed
            text
            @click="closeForm"
          >
            {{ $t('common.cancelBtn') }}
          </v-btn>
          <v-btn
            width="120"
            id="btn-blue-disabled"
            class="font-weight-normal letter-spacing-normal text-capitalize"
            color="primary"
            depressed
            type="submit"
            :disabled="!isFormValid"
          >
          {{ $t('common.save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import FlyoutMenuHead from '../FlyoutMenuHead.vue'
import { mapActions, mapGetters } from 'vuex';
import Group from '../../services/api/Groups.js'
import { mdiMenuDown } from '@mdi/js';

export default {
  name: "EditGroup",
  components: {
    FlyoutMenuHead,
  },
  mounted() {
    this.setGroup(this.$route.params.uuid);
    this.setLocations();
  },
  computed: {
    ...mapGetters({
      getSelectedGroup: "groups/getGroup",
      locations: "locations/getLocationList"
    }),
  },
  watch: {
    getSelectedGroup: {
      handler() {
        this.location = Object.assign({}, this.getSelectedGroup, {
          locations: this.getSelectedGroup.locations.map(loc => loc.uuid)
        });
        this.active = this.getSelectedGroup.active;
      },
      deep: true
    },
  },
  methods: {
    ...mapActions({
      setGroup: "groups/fetchGroup",
      setLocations: "locations/getLocationForLists",
      editGroup: "groups/editGroup"
    }),
    closeForm() {
      this.$router.go(-1)
    },
    async submitForm() {
      const uuid = this.location.uuid;
      const editData = {
        name: this.location.name,
        active: this.location.active,
        location_uuids: this.location.locations // This is already an array of uuids
      };
      this.loader = true;
      try {
        const response = await Group.editGroup(uuid, editData);
        if (response.status === 200) {
          const updatedGroup = response.data;
          this.$store.commit("groups/UPDATE_GROUP_UUID", { uuid, data: updatedGroup });
          this.$store.commit("groups/setgroup", updatedGroup);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader = false;
        this.$router.go(-1);
      }
    },
  },
  data: () => ({
    timer: null,
    isFormValid: false,
    location: {},
    location_rules: {},
    location_types: [],
    countries: [],
    rules: {
      required: (value) => !!value || "Required.",
    },
    icons: {
      mdiMenuDown: mdiMenuDown
    }
  }),
}
</script>

<style lang="scss" scoped>
.overflow-container {
  overflow-x: hidden;
}
.window-title {
  span {
    font-size: 13px;
  }
}

.add-device-btn {
  width: 120px;
}

.bottom-navigation-edit {
  position: absolute !important;
  bottom: 0 !important;
  left: 0;
  right: 0;
  margin: 0;
  box-shadow: 0 -4px 25px 0 rgba(0, 0, 0, 0.13);
}

::v-deep {
  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: var(--v-babyBlueish-base) !important;
    color: white !important;
  }
}
</style>
