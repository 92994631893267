<template>
  <div>
    <v-card class="pa-4 pb-7 scrollable-card" elevation="0" outlined>
      <div class="d-flex justify-space-between">
        <v-card-title class="flex-grow-1">{{ editorData.name }}</v-card-title>
        <v-btn v-show="isUserSysdev" @click="deleteDialog" class="mt-2" elevation="0" color="text-capitalize error">Delete</v-btn>
      </div>
      <vue-json-editor
        ref="jsonEditor"
        v-model="editorData"
        mode="code"
        @change="handleDataChange"
        :expanded-on-start="true"
      ></vue-json-editor>
      <v-card-actions class="ml-0 pl-0 mt-0 pt-6">
        <v-spacer></v-spacer>
          <v-btn width="120" elevation="0" color="text-capitalize gray" @click="cancelModal">Cancel</v-btn>
          <v-btn :disabled="isEditorEmpty" width="120" elevation="0" color="text-capitalize primary" @click="updateApplication">Save</v-btn>
        </v-card-actions>
    </v-card>
    <v-dialog v-model="isDeleteDialog" width="400">
      <v-card class="pa-5">
            <v-row class="pl-5 pr-3">
          <v-card-title>Delete application</v-card-title>
          <v-card-actions class="ml-auto"><v-btn @click="deleteDialog" icon><v-icon>{{ icons.mdiClose }}</v-icon></v-btn></v-card-actions>
        </v-row>
            <v-card-text class="text-secondary">
              Are you sure you want to delete <b>{{ getCurrentApplication.name }}</b> application?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  class="cnl-btn px-8"
                  text
                  @click="deleteDialog"
              >
                Cancel
              </v-btn>
              <v-btn
                  class="red-btn px-8 ml-6"
                  text
                  @click="deleteApplication()">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>
  </div>
</template>
<script>
import VueJsonEditor from 'vue-json-editor';
import { mdiClose } from '@mdi/js';
import { mapActions, mapGetters } from 'vuex';

import Application from '../../services/api/Applications';
import Applications from '../../services/api/Applications';

export default {
  name: "JSONPreview",
  components: { VueJsonEditor },
  props: ['appId'],
  data() {
    return {
      isDeleteDialog: false,
      editorData: {}, // This will be bound to the JSON editor
      initialApplicationData: {},
      changesOnApp: {},
      currentPage: 1,
      pageSize: 35,
      icons: {
      mdiClose: mdiClose
    },
    };
  },
  computed: {
    ...mapGetters('applications',  ['getCurrentApplication']),
    ...mapGetters('accounts', ['getUser']),
    isUserSysdev(){
      return this.getUser.sys_dev;
    }
  },
  watch: {
    appId: {
      immediate: true,
      handler(newVal) {
        this.fetchApplicationData(newVal);
      }
    },
    getCurrentApplication: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.editorData = JSON.parse(JSON.stringify(newVal));
          this.initialApplicationData = JSON.parse(JSON.stringify(newVal));
        }
      }
    },
  },
  methods: {
    ...mapActions('applications', ['getApplication', 'setApplications']),
    fetchApplicationData(appId) {
      this.getApplication(appId);
    },
    deleteDialog(){
      this.isDeleteDialog = !this.isDeleteDialog;
    },
    cancelModal(){
      this.$router.push('/applications');
    },
    deleteApplication(){
      let uuid = this.getCurrentApplication.uuid;
      Applications.deleteApplication(uuid).then(response => {
          console.log('Update successful', response);
          this.setApplications(
            {
              page: this.currentPage,
              pageSize: this.pageSize,
              search: this.searchQuery
            }
          );
          this.isdeleteDialog = false;
          this.$router.push('/applications');
        })
    },
    handleDataChange() {
      // This method should be fired every time the JSON editor's content changes
      this.changesOnApp = this.calculateChanges(this.initialApplicationData, this.editorData);
      console.log("Detected Changes:", this.changesOnApp); // Debug: Check detected changes
    },
    calculateChanges(original, updated) {
      const changes = {};
      alert(updated.uuid)
      Object.keys(updated).forEach(key => {
        if (JSON.stringify(original[key]) !== JSON.stringify(updated[key])) {
          changes[key] = updated[key];
        }
      });
      return changes;
    },
    updateApplication() {
      Application.updateApplication(this.appId, this.editorData)
        .then(response => {
          console.log('Update successful', response);
          this.setApplications(
            {
              page: this.currentPage,
              pageSize: this.pageSize,
              search: this.searchQuery
            });
        })
        .catch(error => {
          console.error('Update failed', error);
          this.$emit('updateError', error);
        });
    },
  }
}
</script>

<style>
.json-editor-container {
  width: 100%;
  height: 69vh;
}
.scrollable-card {
  max-height: calc(100vh - 100px); /* Set a maximum height */
  overflow-y: auto;  /* Enable vertical scrolling */
}
.jsoneditor-vue {
  height: 75vh;
}

.v-sheet.v-card {
    border-radius: 0px;
}
.red-btn {
  background-color: red;
  color: white;
  text-transform: none;
  font-family: Roboto;
}
.cnl-btn {
  text-transform: none;
  font-weight: 300;
  font-family: Roboto;
}
</style>
