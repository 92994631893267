<template>
    <v-btn elevation="0" class="btn_style" @click="emitClick">
      <v-icon size="16">{{ icons.mdiTrayArrowDown }}</v-icon>
      <span class="pl-2">Export</span>
    </v-btn>
  </template>
  
  <script>
  import { mdiArrowCollapseDown } from '@mdi/js'
  export default {
      data(){
          return{
              icons:{
                  mdiTrayArrowDown: mdiArrowCollapseDown
              }
          }
      },
      methods: {
          emitClick() {
              this.$emit('btn-click');
          }
      }
  }
  </script>
  

<style>
.btn_style{
    background-color: var(--v-lineColor-base);
    border:1px solid var(--v-borderColor-base);
    border-radius: 5px;
    font-weight: 450;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; /* Fill the container */
    min-width: 0; /* Allow the button to shrink */
    flex-shrink: 1; /* Allow the button to shrink */
    padding-left: 8px;
    padding-right: 8px;

}

</style>