<template>
  <v-row class="fill-height py-0">
    <template v-if="isGroupTab" >
      <!-- Custom Layout for 'groups' route -->
      <v-row class="pr-4 mt-4">
        <v-col class="py-0 pl-6" cols="12"  md="3" lg="3" xl="3">
          <slot name="left"/>
        </v-col>
        <v-col class="py-0" cols="12" md="8" lg="8" xl="8" >
          <slot name="main"/>
        </v-col>
        <v-col class="py-0" cols="12" md="1" lg="1" xl="1">
          <slot name="right"/>
        </v-col>
        <v-row>
        <v-col class="py-0 scroll-right-lg-y scroll-right-md-y" cols="12">
          <slot name="feed-group"></slot>
        </v-col>
      </v-row>
      </v-row>
    </template>
    <template v-else>
      <!-- Default Layout -->
      <v-col class="py-0 pl-0 pr-4 pr-lg-0 d-none d-lg-flex tree-menu-width" cols="12" lg="2">
        <slot name="left"/>
      </v-col>
      <v-col class="max-width-content" :class="{'pa-0': this.$route.path === '/accounts/inviteUser' || this.$route.path === '/accounts'}" cols="12" lg="12">
        <v-row class="justify-start mx-0 px-0 d-flex flex-column">
          <slot name="top"></slot>
        </v-row>
        <v-row v-if="this.$route.name === 'performance' || this.$route.name === 'monitoring'" class="justify-start mx-0 px-0">
          <v-col class="pa-0 pb-4 pr-4" cols="12" md="11" lg="11" xl="11">
            <slot name="top-search-perform"></slot>
          </v-col>
          <v-col class="pa-0" cols="12" md="1" lg="1" xl="1">
            <slot name="top-btn-perform"></slot>
          </v-col>
        </v-row>
        <v-row v-if="this.$route.name === 'performance' || this.$route.name === 'monitoring'">
          <v-col cols="12" lg="3" xl="3"></v-col>
          <v-col cols="12" lg="9" xl="9">
            <slot name="performance-headings"></slot>
          </v-col>
        </v-row>
        <v-row v-if="this.$route.name === 'accounts' || this.$route.name === 'accounts_action'" class="justify-start mx-0 px-0">
          <v-col class="py-0 pl-0" cols="12" md="11" lg="11" xl="11">
            <slot name="top-search-accounts"></slot>
          </v-col>
          <v-col class="py-0 pl-2" cols="12" md="1" lg="1" xl="1">
            <slot name="top-left-btn"></slot>
          </v-col>
        </v-row>
        <v-row class="justify-end px-2">
          <v-col class="pt-0 pb-4 px-2" v-if="this.view === 'grid'" xs="12" sm="12" md="6" lg="3" xl="3">
            <slot name="left-top"/>
          </v-col>
          <v-col class="pt-0 pb-4 px-2" v-if="this.view === 'grid'" cols="12" sm="12" md="6" lg="8" xl="8">
            <slot name="right-top"/>
          </v-col>
          <v-col class="pt-0 pb-4 px-2" v-if="this.view === 'grid'" cols="12" sm="12" md="6" lg="1" xl="1">
            <slot name="right-top-btn"/>
          </v-col>
          <v-col class="pt-0 pb-4 px-2" v-if="this.view==='format'" cols="12" sm="12" md="6" lg="11" xl="11">
            <slot name="right-top"/>
          </v-col>
          <v-col class="pt-0 pb-4 px-2" v-if="this.view==='format'" cols="12" sm="12" md="6" lg="1" xl="1">
            <slot name="right-top-btn"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-2 pt-0 scroll-right-lg-y scroll-right-md-y">
            <slot name="right"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-2 pt-0 scroll-right-lg-y scroll-right-md-y">
            <slot name="main"/>
          </v-col>
        </v-row>
      </v-col>
      <v-row>
        <v-col class="py-0 pl-0 pr-4 pr-lg-0 d-none d-lg-flex botom-menu-width" cols="12" lg="2">
          <slot name="bottom"/>
        </v-col>
      </v-row>
    </template>
  </v-row>
</template>

<script>
export default {
  name: 'TemplateFeed',
  computed: {
    view() {
      return this.$store.state.view
    },
    isGroupTab(){
      return ['groups', 'group', 'group_action'].includes(this.routeName)
    },
    isRunnersTab(){
      return ['Runners', 'RunnerDetails'].includes(this.routeName)
    },
   routeName() {
     return this.$route.name;
   },
  }
};
</script>

<style>
.tree-menu-width {
  min-width: 274px;
  max-width: 274px;
  max-height: calc(100vh - 124px);
}
.botom-menu-width {
  min-width: 274px;
  max-width: 274px;
}

@media only screen and (min-width: 1264px) {
  .scroll-right-lg-y {
    max-height: calc(100vh - 184px);
    overflow-y: scroll;
  }

  .scroll-left-lg-y {
    max-height: calc(100vh - 240px);
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .max-width-content {
    max-width: calc(100% - 274px);
  }
}


@media only screen and (max-width: 1264px) and (min-width: 960px) {
  .scroll-right-md-y {
    max-height: calc(100vh - 184px);
    overflow-y: scroll;
  }

  .scroll-left-md-y {
    max-height: calc(100vh - 240px);
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
</style>
