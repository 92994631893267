<script src="router/index.js"></script>
<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <v-progress-linear indeterminate v-if="getLoading" class="progress_bar"></v-progress-linear>
    <TemplateDefault/>
  </v-app>
</template>
<script>
import isMobile from "./mixins/isMobile";
import TemplateDefault from "./layouts/TemplateDefault";
import {mapActions, mapGetters} from "vuex";
import logger from "./plugins/logger";
import {TokenStorage} from "@/services/TokenStorage";
import IAM from "@/services/api/IAM";

export default {
  data: () => ({
    showND: false,
    timer: null,
  }),
  mixins: [isMobile],
  components: {
    TemplateDefault,
  },
  metaInfo: {
    title: "NSoft Devices",
    titleTemplate: "%s | NSoft Devices",
  },
  watch: {
    space(oldVal) {
      if (!oldVal) {
        this.$forceUpdate();
      } else {
        location.reload()
      }
    },
   
  },
  computed: {
    ...mapGetters({
      "space": "global/getSpace", 
      "isAutehnticated": "iam/isAuthenticated", 
      "expiresIn": "iam/getExpiresIn", 
      "refreshToken": "iam/getRefreshToken", 
      "getLoading":"global/getLoading"}),
    theme() {
      return (this.$vuetify.theme.dark) ? "dark" : "light";
    },
  
  },
  methods: {
    ...mapActions({
        updateDevice: "devices/updateDevice",
        updateLocationDevices: "locations/updateDeviceLocations",
        updateLocation: "locations/updateLocation",
        refreshDevice: "devices/refreshDevice",
        addAlert: "global/addAlert",
        getUserInfo: "accounts/getUser",
        getDevices: "devices/getDevices",
        getLocations: "locations/getLocations",
      }),
    commitToStore(mutation, payload) {
      return this.$store.commit(mutation, payload);
    },
    setInterval(){
      if(this.isAutehnticated === true && this.timer === null){
        this.timer = setInterval(() => {
          let data = this.refreshToken;
          IAM.refreshToken(data).then((response) => {
            TokenStorage.storeIAMData(response.data);
          }).catch((error) => {
            logger.error(error, "", "IAM")
            this.$store.dispatch('iam/logout')
          })
        }, (this.expiresIn - 60) * 1000)
        }else if(this.isAutehnticated === false && this.timer !== null){
          clearInterval(this.timer);
          this.timer = null;
        }
      },
    subscribeToSpace() {
      let spaceChannel = this.$pusher.subscribe(
          `space_${this.space}`
      );
      spaceChannel.bind("deviceDetailsUpdate", (data) => {
        switch (data.type) {
          case "deviceOnline":
            this.updateDevice({
              uuid: data.deviceUuid,
              fields: [
                {
                  key: "online",
                  value: true,
                },
              ],
            }).then((device) => {
              this.addAlert({
                type: "info",
                message: `Device ${device.name} just became online.`,
              })
                this.getDevices();
            });
            this.updateLocationDevices({
              uuid: data.deviceUuid,
              fields: [
                {
                  key: "online",
                  value: true,
                },
              ],
            });
            break;
          case "deviceOffline":
            this.updateDevice({
              uuid: data.deviceUuid,
              fields: [
                {
                  key: "online",
                  value: false,
                },
              ],
            }).then((device) => {
              this.addAlert({
                type: "warning",
                message: `Device ${device.name} just went offline.`,
              });
              this.getDevices();
            })
            this.getLocations();
            this.updateLocationDevices({
                uuid: data.deviceUuid,
              fields: [
                {
                  key: "online",
                  value: false,
                },
              ],
              });
            break;
          case "deviceInstalled":
            this.refreshDevice({
              uuid: data.deviceUuid
            });
            break;
        }
      });
      spaceChannel.bind("locationDetailsUpdate", (data) => {
          switch (data.type) {
          case "locationOnline":
            this.updateLocation({
              uuid: data.locationUuid,
              fields: [
                {
                  key: "online_status",
                  value: "online",
                },
              ],
            }).then((location) => {
              this.addAlert({
                type: "info",
                message: `Location ${location.name} just became online.`,
              });
            });
            break;
          case "locationPartiallyOnline":
            this.updateLocation({
              uuid: data.locationUuid,
              fields: [
                {
                  key: "online_status",
                  value: "partially_online",
                },
              ],
            }).then((location) => {
              this.addAlert({
                type: "warning",
                message: `Location ${location.name} just went partially online.`,
              });
            });
            break;
          case "locationOffline":
            this.updateLocation({
              uuid: data.locationUuid,
              fields: [
                {
                  key: "online_status",
                  value: "offline",
                },
              ],
            }).then((location) => {
              this.addAlert({
                type: "warning",
                message: `Location ${location.name} just went offline.`,
              });
            });
            break;
        }

      });
      spaceChannel.bind('accountDetailsUpdate', (data)=> {
        switch (data.type) {
          case "accountOnline":
          this.$store.dispatch('accounts/getAccounts')
            break;
          case "accountOffline":
          this.$store.dispatch('accounts/getAccounts')
            break;
        }
    });
    },  
    setInitialFilterState(){
      let data = JSON.parse(localStorage.getItem("checkSort")) || [];
      this.$store.commit("devices/SET_SORT", data);
    }
  },
  created() {
    // Load theme preference from localStorage
    const themePreference = localStorage.getItem("theme_dark");
    if (themePreference !== null) {
      this.$vuetify.theme.dark = themePreference === "true";
    }
  },
  mounted() {
    this.setInitialFilterState();
    const auth = TokenStorage.isAuthenticated();
    this.setInterval();
    const publicPages = [
      null,
      "Register",
      "Invite",
      "Login",
      "ForgotPassword",
      "NewPassword",
      "Success",
    ];
    const authRequired = !publicPages.includes(this.$route.name);
    if (!auth) {
      logger.warn(`Auth is: ${auth}`, "", "IAM")
      return authRequired ? this.$router.replace('/login') : false;
    }
    const email = TokenStorage.getEmail();
    this.getUserInfo(email).then(() => {

      this.$pusher.connection.bind('connected', () => {
        logger.info("Connected", "", "Pusher")
      });
      this.subscribeToSpace();
      if (!this.$store.state.lang) {
        localStorage.setItem("lang", "en");
        this.$i18n.locale = "en";
      }
    });
  },
};
</script>
<style lang="scss">
.progress_bar{
  position:absolute;
  z-index: 1000;
}
.env {
  position: fixed;
  bottom: 0;
}
.row {
  margin-top: 0;
  margin-bottom: 0;
}

.row + .row {
  margin-top: 0;
}

* {
  outline: none;
}

::-webkit-scrollbar {
  width: 0 !important;
}

.theme--light.v-divider {
  border-color: var(--v-lineColor-base) !important;
  z-index: 200;
}
.theme--dark.v-divider {
  z-index: 900;
}

.text-primary {
  color: var(--v-textColor-base) !important;
}

.text-secondary {
  color: var(--v-textColor-lighten4) !important;
}
.text-warnning {
  color: var(--v-textColor-red-darken-2) !important;
}

.alert--bottom-fixed {
  width: 100%;
  z-index: 999;
  bottom: 0px;
  position: fixed !important;
  pointer-events: none !important;
  
}

.v-navigation-drawer__border {
  background-color: transparent !important;
}

.v-navigation-drawer {
  transition-duration: 0.35s !important;
}

.side-menu {
  right: 64px;
}

.top-initial {
  top: initial !important;
}

.centered-input * {
  text-align: center;
}

.centered-input .v-input__append-outer {
  margin-top: 0px !important;
}


.v-expansion-panel:before {
  box-shadow: none !important;
}

.subtitle-2 {
  line-height: 16px !important;
}

.body-text {
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
}

.letter-spacing-normal {
  letter-spacing: normal !important;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
> .v-input__control
> .v-input__slot
fieldset {
  border: thin solid rgba(34, 34, 34, 0.2);
}


@media screen and (min-width: 600px) {
  .overflow-md-y-hidden {
    overflow-y: hidden;
  }

  .content-height {
    max-height: calc(100vh - 177px);
  }
}
</style>
