<template>
  <div class="overflow-container overflow-y-auto scroll-right-md-y scroll-right-lg-y">
    <v-row v-for="(section, i) in data.sections" v-bind:key="i"
    >
      <v-col md="12" class="d-flex align-center justify-space-between mt-10">
        <span class="subtitle text-secondary">{{ section.title }}</span>
      </v-col>
      <v-col
          v-for="(input, j) in section.inputs"
          v-bind:key="j"
          :md="input.gridSize"
          :xs="12"
          :cols="12"
          class="d-flex align-center justify-space-between">
        <v-text-field
            v-model="formValues[input.value]"
            :maxlength="256"
            :counter="256"
            v-on:input="onInputChange"
            v-if="input.type === 'text' || input.type === 'number' || input.type === 'email'"
            height="40px"
            :placeholder="input.placeholder"
            dense
            outlined>
          <template #label>
            <span class="pl-1 pr-1 text-primary">{{ input.label }}</span>
          </template>
        </v-text-field>
        <v-autocomplete
            v-if="input.type === 'tags'"
            v-model="formValues[input.value]"
            v-on:input="onInputChange"
            :items="input.options.items"
            :placeholder="input.placeholder"
            dense
            outlined
            height="40px"
            :append-icon="icons.mdiMenuDown"
            @update:search-input="onTagChange"
            :counter="input.options.counter"
            :item-text="input.options.text"
            :item-value="input.options.value"
            @keydown.enter="onAddTag"
            :search-input.sync="tagToAdd"
            @input="tagToAdd = null"
            chips
            hint="asd"
            persistent-hint
            maxlength="20"
            deletable-chips
            multiple>
          <template v-slot:message>
            <div class="mt-2 ml-n3">
              <span>To add a new hint, type it and press ENTER.</span>
            </div>
          </template>
          <template #label>
            <span class="pl-1 pr-1">Tags</span>
          </template>
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title class="d-flex align-start justify-space-between">
                <span class="font-weight-regular">{{ data.item.name }}</span>
                <span class="font-weight-light">{{ data.item['total_number'] || 0 }} devices and locations</span>
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-slot:no-data>
            <v-list-item-content>
              <v-list-item-title class="d-flex align-start justify-space-between px-4">
                <span class="font-weight-regular caption">{{ tagToAdd }}</span>
                <span class="font-weight-light caption">New</span>
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
        <v-select
            v-if="input.type === 'select'"
            :append-icon="$vuetify.icons.values.chevronDown"
            v-on:input="onInputChange"
            :items="input.options.items"
            v-model="formValues[input.value]"
            :placeholder="input.placeholder"
            outlined
            dense
            height="40px"
            :item-text="input.options.text"
            :item-value="input.options.value">
          <template #label>
            <span class="pl-1 pr-1 text-primary">{{ input.label }}</span>
          </template>
        </v-select>
        <v-textarea
            v-if="input.type === 'textarea'"
            v-model="formValues[input.value]"
            v-on:input="onInputChange"
            outlined
            persistent-hint
            :hint="input.options.hint"
            :counter="input.options.counter"
            :placeholder="input.placeholder"></v-textarea>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row class="text-right bottom-navigation-edit py-4 px-2"
    ><v-col align-self="end">
        <v-btn
            width="120"
            class="mr-2 font-weight-regular letter-spacing-normal text-capitalize"
            depressed
            @click="closeForm()"
            text>{{ $t('common.cancelBtn') }}
        </v-btn>
        <v-btn
            id="btn-blue-disabled"
            width="120"
            class="primary font-weight-normal letter-spacing-normal text-capitalize"
            depressed
            :disabled="!changed"
            color="primary"
            @click="submitForm()">{{ type === 'edit' ? 'Save' : $t('assets.devices.addRunner.addBtn') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiMenuDown } from '@mdi/js';
export default {
  props: ["formData", "type", "currentData"],
  watch: {
    formData: function (newVal) {
      this.generateForm(newVal);
    }
  },
  mounted() {
    if (Object.keys(this.$props.formData).length > 0) {
      this.generateForm();
    }
  },
  methods: {
    onInputChange(){
      if(this.type === 'edit'){
         if(
            this.formValues.name !== this.currentData.name ||
            this.formValues.description !== this.currentData.description ||
            this.formValues.tags !== this.currentData.tags
          )
          {
            this.changed = true;
          }
          else
            {
              this.changed = false;
            }
    }
    else{
      this.changed = true;
    }
    if(this.type !== 'edit'){
      if(
          this.formValues.name !== '' &&
          this.formValues.location_uuid !== '' 
        )
        {
          this.changed =true;
        }
        else
        {
          this.changed = false;
        }
    }
     
    },
    closeForm() {
      this.$emit('onClose');

    },
    submitForm() {
      this.$emit('onSubmit', this.formValues);
    },
    onAddTag() {
      this.$emit('onAddTag', this.tagToAdd);
    },
    onTagChange(tag) {
      this.tagToAdd = tag;
    },
    generateForm(){
      this.data = this.$props.formData;
      this.data.sections.forEach(section => {
        Object.keys(section.inputs).map(field => {
          if (this.$props.currentData && this.$props.currentData[field]) {
            this.formValues[field] = this.$props.currentData[field]
          } else {
            if (field === 'tag_uuids'){
              this.formValues[field] = []
            } else {
              this.formValues[field] = ''
            }
          }
          if (section.inputs[field].options?.action) {
            this.$store.dispatch(section.inputs[field].options.action).then(response => {
              section.inputs[field].options.items = response;
            })
          }
        })
      })
    },

  },
  data: () => ({
    changed: false,
    formValues: {},
    data: {},
    tagToAdd: "",
    icons: {
        mdiMenuDown: mdiMenuDown
      }
    
  })
};
</script>
<style lang="scss" scoped>
.overflow-container {
  overflow-x: hidden;
}
.bottom-navigation-edit {
  position: absolute;
  bottom: 0 !important;
  left: 0;
  right: 0;
  margin: 0;
  box-shadow: 0 -4px 25px 0 rgba(0, 0, 0, 0.13);
}
::v-deep {
  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: var(--v-babyBlueish-base) !important;
    color: white !important;
  }

}
</style>
