<template>
  <v-navigation-drawer
      app
      color="blackish"
      width="64"
      dark
      right
      mobile-breakpoint="600"
  >
    <v-list
        dense
        nav
        class="py-1"
    >
      <v-list-item class="px-1">
        <v-list-item-avatar @click="toggleModal" style="cursor: pointer">
          <img
              src="../assets/avatar.png"
              alt="avatar"/>
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item class="px-1" @click="toggleTheme" style="z-index: 1000;">
        <v-list-item-content>
          <v-icon>{{$vuetify.theme.dark ? icons.mdiDay : icons.mdiNight}}</v-icon>
        </v-list-item-content>
      </v-list-item>
      <v-divider vertical  class="vertical_divider"></v-divider>
      <v-list-item :to="item.route" style="z-index: 1000;">
        <v-list-item-content >
          <v-icon>{{ icons.mdiTune }}</v-icon>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapActions} from "vuex";
import {mdiWeatherNight, mdiWeatherSunset, mdiTune} from "@mdi/js";

export default {
  data() {
    return {
      item: {
        route: "working_time"
      },
      icons: {
        mdiNight: mdiWeatherNight,
        mdiDay: mdiWeatherSunset,
        mdiTune: mdiTune
      }
      // items: [
      //   {
      //     title: 'Directory',
      //     icon: 'mdi-folder',
      //   },
      //   {
      //     title: 'Assets',
      //     icon: 'mdi-briefcase',
      //   },
      // ],
    };
  },
  methods: {
    ...mapActions({"toggleAccountModal": "accounts/toggleAccountModal"}),
    toggleModal() {
      this.toggleAccountModal();
    },
    toggleTheme() {
      // Toggle Vuetify theme
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      // Save theme preference to localStorage
      localStorage.setItem("theme_dark", this.$vuetify.theme.dark);
    },
  }
};
</script>

<style scoped>
.vertical_divider{
  height: calc(100vh - 177px);
  background-color: transparent !important;
  border-color: transparent !important;
}
</style>
