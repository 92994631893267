<template>
  <v-row>
    <v-col class="py-0">
      <v-text-field
          dense
          v-model="inputValue"
          :class="this.isDarkTheme ? 'search-bar-dark':'search-bar-light'"
          placeholder="Search"
          outlined
          :append-icon="icons.mdiMagnify"
          v-on:input="(val) => {this.$emit('input', val)}"
      ></v-text-field>
    </v-col>
  </v-row>
</template>
<script>
import {mdiMagnify} from "@mdi/js";

export default {
  name: 'Search',
  data() {
    return {
      inputValue: null,
      icons: {
        mdiMagnify: mdiMagnify,
      }
    };
  },
  computed:{
    isDarkTheme() {
      return this.$vuetify.theme.dark || false;
    },
  },
  methods: {
    handleClearInput() {
      this.inputValue = '';
    }
}

};
</script>

<style
    lang="scss"
    scoped>
::v-deep {
  .v-text-field__details {
    display: none;

  }

  .v-input__slot {
    margin-bottom: 0 !important;

  }

  .search-bar-light {
    background-color: #ffffff;
  }
}
</style>
